import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Image from "gatsby-image"
import React from "react"
import Layout from "../components/Layout/Layout"
import ArrowBlue from "../assets/arrow-blue-left.svg"
import ArrowBlackUp from "../assets/arrow-black-up.svg"
import Quote from "../assets/quote-icon.svg"
import { SeeMoreButton } from "../components/seeMoreButton/seeMoreButton"
import styles from "../styles/projectPage.module.scss"
import WhiteSection from "../components/whiteSection/whiteSection"
import tt from "../helpers/translation"
import { useScrollPercentHandler } from "../hooks/useScrollPercent"
import { useScrollHandler } from "../hooks/useScroll"

const ProjectPage = ({ data, pageContext }) => {
  const infoPage = data.allProjectsFichaJson.edges[0].node
  let imagesMobileDesktop = []
  let logosArr = []
  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === data.allProjectsFichaJson.edges[0].node.name
  })
  infoPage.images_mobile.map(element => {
    const {
      childImageSharp: { fluid: PicDesktop },
    } = data.allFile.nodes.find(
      node => node.childImageSharp.fluid.src.indexOf(`${element}`) > -1
    )
    imagesMobileDesktop.push(PicDesktop)
  })
  const {
    childImageSharp: { fluid: background },
  } = data.allFile.nodes.find(
    node =>
      node.childImageSharp.fluid.src.indexOf(`${infoPage.imageHeader}`) > -1
  )
  const {
    childImageSharp: { fluid: firstPicDesktop },
  } = data.allFile.nodes.find(
    node => node.childImageSharp.fluid.src.indexOf(`${infoPage.image1}`) > -1
  )
  const {
    childImageSharp: { fluid: secondPicDesktop },
  } = data.allFile.nodes.find(
    node => node.childImageSharp.fluid.src.indexOf(`${infoPage.image2}`) > -1
  )
  infoPage.images_technology.map(element => {
    const {
      childImageSharp: { fluid: logo },
    } = data.logoImages.nodes.find(
      foto => foto.childImageSharp.fluid.src.indexOf(`${element}`) > -1
    )
    logosArr.push(logo)
  })

  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url,
    titleSeo: seoDataJson.title,
    description: seoDataJson.description,
    alternateLanguage: seoDataJson.alternateLanguage,
    alternateUrl: seoDataJson.alternateUrl,
  }

  const resultsPercentage = data => {
    return infoPage.highlights.map(element => (
      <div
        className={styles.result_container}
        key={`${element.bold} + ${element.text}`}
      >
        <p className={styles.text}>
          <strong>{element.bold}</strong> {element.text}
        </p>
        <p className={styles.emphasize}>{element.percentage}</p>
      </div>
    ))
  }

  const resultsMobileDesktopImage = () => {
    return imagesMobileDesktop.map(element => (
      <React.Fragment key={element.src}>
        <Image alt="" fluid={element} className={`${styles.project_image}`} />
      </React.Fragment>
    ))
  }

  const logoOnImage = () => {
    return logosArr.map(element => (
      <div className={styles.tech_logo_container} key={element.src}>
        <Image alt="" className={styles.image} fluid={element} />
      </div>
    ))
  }

  const previousPage = () => {
    if (pageContext.lang === "ca") {
      return "/ca/projectes/"
    }
    if (pageContext.lang === "es") {
      return "/es/proyectos/"
    } else {
      return "/our-work/"
    }
  }

  const scrollPerc = useScrollPercentHandler(50, 200)
  const showArrow = useScrollHandler()

  return (
    <Layout
      leftContactUs
      lan={data.allProjectsFichaJson.edges[0].node.lang}
      location="ourwork"
      urls={resultUrls}
      seoData={seoData}
    >
      <div
        className={styles.flecha_subida}
        style={{
          display: showArrow ? "none" : "block",
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <ArrowBlackUp />
      </div>
      <React.Fragment>
        <BackgroundImage
          className={styles.first_image_container}
          fluid={background}
        >
          <div className={styles.text_container}>
            <h1>{infoPage.project}</h1>
            <p className={styles.subtitle}>{infoPage.title}</p>
          </div>
        </BackgroundImage>
        <WhiteSection
          subtitle={tt("Project overview", pageContext.lang)}
          text={infoPage.project_overview}
        />
        <Image
          alt=""
          className={`${styles.project_image}`}
          fluid={firstPicDesktop}
        />
        <WhiteSection
          subtitle={tt("The Challenge", pageContext.lang)}
          text={infoPage.challenge_text}
        />
        <Image
          alt=""
          fluid={secondPicDesktop}
          className={`${styles.project_image}`}
        />
        <WhiteSection
          subtitle={tt("Features & Technology", pageContext.lang)}
          text={
            <div>
              <p className={styles.description}>
                {infoPage.features_technology_text}
              </p>
              <div className={styles.used_technlogies}>
                <h3>{tt("Used technologies", pageContext.lang)}</h3>
                <div className={styles.technology_stack}>{logoOnImage()}</div>
              </div>
            </div>
          }
        />
        {infoPage.testimonial_text !== "" ? (
          <div className={`${styles.blue_section_container}`}>
            <div className={styles.text_container}>
              <div className={styles.quote_container}>
                <Quote />
              </div>
              <div className={styles.testimonial_text}>
                {infoPage.testimonial_text}
              </div>
              <div className={styles.testimonial_name}>
                {infoPage.testimonial_name}
              </div>
              <div className={styles.testimonial_position}>
                {infoPage.testimonial_position}
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.image_wrapper}>
          {resultsMobileDesktopImage()}
        </div>
        <div className={styles.back_button_container}>
          <Link
            to={previousPage()}
            className={`${styles.button} ${styles.back}`}
          >
            <ArrowBlue />
            <span>{tt("Back to Our Work", pageContext.lang)}</span>
          </Link>
        </div>
        <div className={`${styles.next_project_container}`}>
          <div className={styles.text_container}>
            <h2>{infoPage.another_project_title}</h2>
            <h3>{infoPage.another_project_subtitle}</h3>
            <SeeMoreButton
              text={tt("Read more", pageContext.lang)}
              sendTo={infoPage.another_project_link}
            />
          </div>
        </div>
      </React.Fragment>
    </Layout>
  )
}

export const pageQuery = () => graphql`
  query projectsFichaQuery(
    $lang: String!
    $pageName: String!
    $projectPicsRoute: String!
  ) {
    allFile(
      filter: {
        relativePath: { glob: $projectPicsRoute }
        extension: { regex: "/png|jpg/" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    logoImages: allFile(
      filter: {
        relativePath: { glob: "logos/*" }
        extension: { regex: "/png|jpg/" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seoDataJson(lang: { eq: $lang }, name: { eq: $pageName }) {
      url
      title
      description
      alternateLanguage
      alternateUrl
    }
    allProjectsFichaJson(
      filter: { lang: { eq: $lang }, name: { eq: $pageName } }
    ) {
      edges {
        node {
          name
          lang
          project
          title
          project_overview
          challenge_text
          features_technology_text
          testimonial_name
          testimonial_position
          testimonial_text
          another_project_title
          another_project_subtitle
          another_project_link
          imageHeader
          image1
          image2
          images_mobile
          images_technology
        }
      }
    }
  }
`

export default ProjectPage
